<template>
  <div class="box">
    <div class="fromBox">
      <div class="section-heading">
        <h2>欢迎 <span>订购 </span></h2>
        <h4>
          请留下您的信息，我们的销售人员会第一时间和您取得联系。
        </h4>
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row class="one">
          <el-col :span="12"
            ><el-form-item prop="Name">
              <el-input
                v-model="ruleForm.Name"
                placeholder="您的姓名"
                prefix-icon="el-icon-user-solid"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item prop="mailbox">
              <el-input
                v-model="ruleForm.mailbox"
                placeholder="您的邮箱"
                prefix-icon="el-icon-s-comment"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="24"
            ><el-form-item prop="Phone">
              <el-input
                v-model="ruleForm.Phone"
                placeholder="您的电话"
                prefix-icon="el-icon-phone"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <div class="codebox">
              <span @click="randyzm()">{{ code }}</span>
            </div>
            <el-form-item class="yzmcode" prop="yzm">
              <el-input v-model="ruleForm.yzm" placeholder="请输入验证码">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12"
            ><el-form-item prop="Type">
              <el-input
                class="type"
                v-model="num"
                placeholder="订购类型"
                prefix-icon="el-icon-s-opportunity"
              ></el-input></el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item prop="desc">
              <el-input
                :rows="5"
                type="textarea"
                v-model="ruleForm.desc"
                placeholder="您本地使用E R P系统名称描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提 交 订 购</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      code: "",
      ruleForm: {
        Name: "",
        mailbox: "",
        Phone: "",
        yzm: "",
        Type: "",
        num: "",
        desc: "",
      },
      rules: {
        Name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        mailbox: [
          {
            required: true,
            type: "email",
            message: "请填写正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],

        Phone: [
          {
            required: true,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请填写正确的联系电话",
            trigger: "blur",
          },
        ],
        yzm: [
          {
            //验证码判断
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请填写验证码"));
              } else {
                if (value !== "") {
                  //   var code = this.code;
                  if (
                    this.code.toUpperCase() !== this.ruleForm.yzm.toUpperCase()
                  ) {
                    callback(new Error("请输入正确的验证码"));
                  }
                }
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        // Type: [
        //   { required: true, message: "请选择订购类型", trigger: "change" },
        // ],

        desc: [
          {
            required: true,
            message: "请填写您本地使用E R P系统名称",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    submitForm() {
      console.log(this.ruleForm);
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          this.$message.error("请检查是否完成填写内容后再提交!");
          //   return;
        }

        this.$http
          .post("/Base/Base_Purchase/SaveData", {
            Name: this.ruleForm.Name,
            Email: this.ruleForm.mailbox,
            Telephone: this.ruleForm.Phone,
            Theme: this.ruleForm.userTopic,
            Demand: this.ruleForm.desc,
            Type: this.$route.query.value,
            // Price: 1000,
          })
          .then((res) => {
            if (res.Success) {
              this.$message.success(
                "提交成功，工作人员将会在七个工作日内进行反馈，请注意查收邮件！"
              );
              this.$refs["ruleForm"].resetFields();
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    // 方法
    randyzm() {
      //生成随机验证码
      let code = "";
      let coden = "";
      var codeLength = 4; //验证码的长度
      var random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z"
      ); //随机数
      for (var i = 0; i < codeLength; i++) {
        //循环操作
        var index = Math.floor(Math.random() * 62); //取得随机数的索引（0~61）
        code += random[index]; //根据索引取得随机数加到code上
        coden += random[index]; //根据索引取得随机数加到code上
      }
      this.code = coden; //把code值赋给验证码
      console.log(this.code);
    },
  },

  mounted() {
    this.randyzm();
  },
  created() {
    this.num = this.$route.query.value;
    // console.log(this.$route.query.value);
  },
  computed: {},
  watch: {},
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
.box {
  position: relative;
  width: 60%;
  margin-left: 21.5%;
}
.fromBox {
  width: 100%;
  margin-top: 7%;
}

.demo-ruleForm {
  margin-top: 5%;
  margin-left: 1%;
  width: 105%;
  position: relative;
}
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #3c3d3de3;
  line-height: 40px;
  padding: 0 20px 0 0;
  box-sizing: border-box;
}

.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ffffff;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 10px 20px 0 rgb(194 188 210 / 20%);
}
.el-input__inner {
  width: 100%;
  display: block;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 18px 30px 18px 60px;
  border: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-box-shadow: 0 10px 20px 0 rgba(194, 188, 210, 0.2);
  box-shadow: 0 10px 20px 0 rgba(194, 188, 210, 0.2);
}
.el-input__inner:focus {
  -webkit-box-shadow: 0 15px 30px 0 rgba(194, 188, 210, 0.2);
  box-shadow: 0 15px 30px 0 rgba(194, 188, 210, 0.2);
}

.el-input--prefix .el-input__inner {
  padding-left: 55px;
  font-weight: 500;
  font-size: 15px;
}
.el-col-24 {
  width: 97%;
}
.el-input__inner {
  padding-left: 10px;
  height: 30%;
}
.el-icon-user-solid:before {
  top: -7px;
  left: -10px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  justify-content: center;
  color: #5268f2;
  font-size: 17px;
}
.el-icon-s-comment:before {
  top: -6px;
  left: -10px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  justify-content: center;
  color: #5268f2;
  font-size: 17px;
}
.el-icon-phone:before {
  top: -6px;
  left: -10px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  justify-content: center;
  color: #5268f2;
  font-size: 17px;
}
.el-icon-s-opportunity:before {
  top: -6px;
  left: -10px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  justify-content: center;
  color: #5268f2;
  font-size: 17px;
}

.section-heading {
  display: block;
  position: relative;
  margin: 14px 270px;
  display: block;
  text-align: center;
  width: 55%;
}
.section-heading h2 {
  margin-bottom: 15px;
  padding-bottom: 10px;
  text-align: center;
  display: block;
  position: relative;
}

.section-heading h2 {
  font-size: 40px;
  font-weight: 400;
  color: #5268f2;
  margin: 0;
}
.section-heading h2:after {
  content: "";
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 50%;
  position: absolute;
  background: #5268f2;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section-heading h4 {
  font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #7c7885;
    margin-top: 4%;
}

.el-row {
  width: 90%;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.codebox {
  z-index: 999;
  height: 55px;
  margin-bottom: -55px;
  margin-left: 37px;
  padding: 13px 7px;
  transition: all 0.5s ease;
  text-transform: capitalize;
}
.yzmcode {
  width: 85%;
  float: left;
}
.el-form-item .type {
  width: 97%;
  margin-left: -3%;
}

.el-button {
  margin-left: 2%;
  font-size: 16px;
  font-weight: 900;
  padding: 16px 35px;
  text-align: center;
  border-radius: 500px;
  display: inline-block;
  background: #5268f2;
  transition: all 0.5s ease;
  text-transform: capitalize;
  color: #fff !important;
  border: 1px solid #5268f2;
  box-shadow: 0 15px 30px 0 rgb(82 104 242 / 15%);
}
.codebox span {
  z-index: 1100; /*元素显示在最上层*/
  display: inline-block;
  text-align: center;
  /* width: 62px; */
  font-size: 21px;
  font-weight: 600;
  height: 55px;
  margin-top: -12px;
  margin-left: 420px;
  line-height: 44px;
  color: rgb(255, 255, 255);
  background-color: rgb(82, 104, 242);
  border-radius: 7px;
  padding: 5px 10px;
}

.el-input {
  position: relative;
  font-size: 15px;
  font-weight: 500;
}

.one {
  width: 87.5%;
}
</style>